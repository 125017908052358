import React,  { Component, Fragment } from 'react'

 class FeaturedBlog extends Component {
    render() {
        return (
           <Fragment>
                  <div className="mr-15 ">
        <div className="fePost">
          <img src={require('../../assets/img/fe2.jpg')} alt="fePost" />
          <div className="fePostC">
            <div className="pd-15">
              <div className="fs-13 span">FEATURED</div>
              <h3>Lorem Ipsum is simply dummy text of the printing
                and typesetting</h3>
              <p className="fs-13">By <b>Ramu Kaka</b> 2 years ago<i>PEOIPLE</i></p>
            </div>
          </div>
        </div>
      </div>
           </Fragment>
        )
    }
}

export default FeaturedBlog;
