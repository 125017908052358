import React,  { Component, Fragment } from 'react'
import Header from './common/Header';
import Footer from './common/Footer';
import BlogList from './blog/BlogList';
import Banner from './Banner';
 class Home extends Component {
    render() {
        return (
            <Fragment>
                <Header />
                <Banner/>
                <BlogList/>
                <Footer />
            </Fragment>
        )
    }
}

export default Home;
