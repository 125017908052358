import React,  { Component, Fragment } from 'react'
import {Link} from "react-router-dom";
class Header extends Component {
    render() {
        return (
           <Fragment>
                <header>
        <div className="cn-width">
          <div className="nav-bar mRow">
            <div className="brand">
            <Link to="/"> <img src={require('../../assets/img/brand.svg')} alt="WOOK" /></Link></div>
            <nav className="nav txR">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/category">Category</Link>
              <Link to="/auther">Authors</Link>
            </nav>
          </div>
        </div>
      </header>
           </Fragment>
        )
    }
}

export default Header
