import React,  { Component, Fragment } from 'react'
class Footer extends Component {
    render() {
        return (
           <Fragment>
                <footer className="grBG">
        <div className="cn-width ">
          <div className="txC newsLetter">
            <h1>Join our Newsletter</h1>
            <form>
              <input type="text" placeholder="Your Email Addess" />
              <input type="submit" defaultValue="Subcribe" className="trngs" />
            </form>
          </div>
          <div className="mRow foot">
            <div className="cl-3">
              <div className="mr-15">
                <img src={require('../../assets/img/brand.svg')} alt="WOOK" /></div>
            </div>
            <div className="cl-3">
              <div className="mr-15">
                <nav className="nav txC"><a href="/">Home</a>
                  <a href="/">Category</a>
                  <a href="/">Authors</a></nav>
              </div>
            </div>
            <div className="cl-3" />
          </div>
          <p className="fs-13 txC">© 2019 All Right Reserved. By Wook.</p>
        </div>
      </footer>
           </Fragment>
        )
    }
}

export default Footer
