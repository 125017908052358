import React,  { Component, Fragment } from 'react'
import FeaturedBlog from './blog/FeaturedBlog';
export class Banner extends Component {
    render() {
        return (
           <Fragment>
           <section className="tpSec">
<div className="cn-width">
  <div className="mRow">
    <div className="cl-1-3">
      <div className="mr-15">
        <div className="fePost">
          <img src={require('../assets/img/fe1.jpg')} alt="fePost" />
          <div className="fePostC">
            <div className="pd-20">
              <div className="fs-13 span">FEATURED</div>
              <h1>Lorem Ipsum is simply dummy text of the printing
                and typesetting</h1>
              <p className="fs-18">By <b>Ramu Kaka</b> 2 years ago<i className="fs-16">PEOIPLE</i></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="cl-3">
     <FeaturedBlog/>
     <FeaturedBlog/>
    </div>
  </div>
</div>
</section>
  </Fragment>
        )
    }
}

export default Banner