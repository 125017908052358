import React,  { Component, Fragment } from 'react'
import Header from './common/Header';
import Footer from './common/Footer';
 class Category extends Component {
    render() {
        return (
           <Fragment>
             <Header />
                <section className="tpSec">
                  <div className="cn-width">
                   <h1>Category List</h1>
                  </div>
                  </section>
                <Footer />     
           </Fragment>
        )
    }
}

export default Category;
