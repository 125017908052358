import React,  { Component, Fragment } from 'react'

 class RecentBlog extends Component {
    render() {
        return (
           <Fragment>
                  <a href="/" className="sidePost">
                    <h2>Lorem Ipsum is simply dummy
                      text of the printing</h2>
                    <p className="fs-13">By <b>Ramu Kaka</b> 2 years ago</p>
                  </a>
           </Fragment>
        )
    }
}

export default RecentBlog
