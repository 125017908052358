import React,  { Component, Fragment } from 'react'
import BlogCard from './BlogCard';
import RecentBlog from './RecentBlog';
 class BlogList extends Component {
    render() {
        return (
           <Fragment>
                       <section className="conSec">
          <div className="cn-width">
            <div className="mRow wtBG">
              <div className="cl-1-3 grBG">
                <div className="mRow pd-tb-20 mr-r-20">
                 <BlogCard />
                 <BlogCard />
                 <BlogCard />
                 <BlogCard />
                </div>
              </div>
              <div className="cl-3 ">
                <span className="sidHEADing fs-16">RECENT POSTS</span>
                <div className="pd-lrtb-15-20 txL">
                   <RecentBlog />
                   <RecentBlog />
                   <RecentBlog />
                   <RecentBlog />
                   <RecentBlog />
                </div>
              </div>
            </div>
          </div>
        </section>
           </Fragment>
        )
    }
}

export default BlogList
