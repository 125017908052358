import React,  { Component } from 'react'
import Home from './Home';
import About from './About';
import Category from './Category';
import Auther from './Auther';
import BlogDetails from './blog/BlogDetails';

import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
class App extends Component {
    render() {
        return (
          <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/about" component={About}/>
            <Route path="/category" component={Category}/>
            <Route path="/auther" component={Auther}/>
            <Route path="/blog-details" component={BlogDetails}/>
            <Redirect to="/not-found" component={Home} />
          </Switch>
        </BrowserRouter>
           
        )
    }
}

export default App
