import React,  { Component, Fragment } from 'react'
import {Link} from "react-router-dom";
 class BlogCard extends Component {
    render() {
        return (
           <Fragment>
                  <div className="cl-2">
                    <div className="mr-10">
                      <div className="postT">
                        <img src={require('../../assets/img/po1.jpg')} alt="fePost" />
                        <div className="pd-15">
                          <h2>Lorem Ipsum is simply dummy
                            text of the printing</h2>
                          <p className="fs-16">By <b>Ramu Kaka</b> 2 years ago<i>PEOIPLE</i></p>
                          <Link to="/blog-details">Read More -</Link>
                        </div>
                      </div>
                    </div>
                  </div>
           </Fragment>
        )
    }
}

export default BlogCard
